import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

const counterExchanges = exchanges => {
  const allExchanges = exchanges.map(exchange => exchange.stockExchanges.nodes)
    .flat()
    .reduce((accum, curVal) => {

      !accum[curVal.id]
        ? accum[curVal.id] = { ...curVal, count: 1 }
        : accum[curVal.id] = { ...curVal, count: accum[curVal.id].count + 1 }

      return accum
    }, {})
  return Object.values(allExchanges).sort((a, b) => a.count - b.count)
}

const CompaniesExchanges = () => {
  const { filters } = useStaticQuery(graphql `
    query {
      filters: allWpCompany {
        nodes {
          stockExchanges {
            nodes {
              id
              name
              uri
            }
          }
        }
      }
    }
  `)

const exchanges = counterExchanges(filters.nodes).reverse()
  return (
    <CompaniesExchangesBlock>
      <ul className="CompaniesExchanges__list">
        {exchanges.map(exchange => (
          <li className="CompaniesExchanges__list_item" key={ exchange.id }>
            <Link to={exchange.uri}>{ exchange.name }</Link>
          </li>
        ))}
      </ul>
    </CompaniesExchangesBlock>
  )
}

export default CompaniesExchanges

const CompaniesExchangesBlock = styled.div `
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 96%;
  max-width: 1500px;
  overflow: hidden;

  .CompaniesExchanges__list {
    display: flex;
    align-items: center;

    * + li {
      margin-top: 0;
    }
  }
`
