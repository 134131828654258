import React from 'react'
import styled from 'styled-components'
import PaginationCounter from '../Pagination/PaginationCounter'

export const CompaniesTop = ({ pagination, total }) => {

  return (
    <CompaniesTopBlock>
      {/* <div>Всего компаний: { total }</div> */}
      <PaginationCounter pagination={ pagination } />
    </CompaniesTopBlock>
  )
}

export default CompaniesTop

const CompaniesTopBlock = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem 0;
`
