import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const PaginationCounter = ({ pagination }) => {

  return (
    <PaginationCounterBlock>
       <div className="PaginationCounter_content">
         <span className="PaginationCounter_num">{ pagination.currentPage }</span>
         <span className="PaginationCounter_num__text">из</span>
         <span className="PaginationCounter_num">{ pagination.numPages }</span>
       </div>
      <Link className={`Btn Btn-chevron ${pagination.isFirst ? 'isDisabled' : ''}`} to={ pagination.prevPage }>
        <BiChevronLeft />
      </Link>
      <Link className={`Btn Btn-chevron ${pagination.isLast ? 'isDisabled' : ''}`} to={ pagination.nextPage }>
        <BiChevronRight />
      </Link>
    </PaginationCounterBlock>
  )
}

export default PaginationCounter

const PaginationCounterBlock = styled.div `
  display: flex;
  justify-content: center;

  .PaginationCounter_content {
    margin-right: 2rem;
    font-size: 1.3rem;
    line-height: 1;
    letter-spacing: 0;
  }

  .PaginationCounter_num{
    display: inline-block;
    border-radius: 0.3rem;
    width: 3rem;
    height: 3rem;
    font-weight: 500;
    text-align: center;
    line-height: 3rem;
    color: ${props => props.theme.blueGray};
    background-color: ${props => props.theme.white};
  }

  .PaginationCounter_num__text {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
  }

`
