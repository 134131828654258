import React from 'react'
import styled from 'styled-components'
import PaginationNav from '../Pagination/PaginationNav'
import PaginationCounter from '../Pagination/PaginationCounter'

export const CompaniesBottom = ({ pagination }) => {
  return (
    <CompaniesBottomBlock>
      <PaginationNav pagination={ pagination }/>
      <PaginationCounter pagination={ pagination }/>
    </CompaniesBottomBlock>
  )
}

export default CompaniesBottom

const CompaniesBottomBlock = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
`
