import React from 'react'
import { graphql } from 'gatsby'
import { useCompaniesQuery } from '../hooks/useCompaniesQuery'
import Layout from '../components/Layout/Layout'
import CompaniesHero from '../components/Company/CompaniesHero'
import CompaniesList from '../components/Company/CompaniesList'
import CompaniesContent from '../components/Company/CompaniesContent'
import CompaniesTop from '../components/Company/CompaniesTop'
import CompaniesBottom from '../components/Company/CompaniesBottom'
import CompaniesBody from '../components/Company/CompaniesBody'
import CompaniesMain from '../components/Company/CompaniesMain'
import CompaniesSector from '../components/Company/CompaniesSector'
import CompaniesExchanges from '../components/Company/CompaniesExchanges'
import SideBar from '../components/SideBar/SideBar'
import SideBarContent from '../components/SideBar/SideBarContent'

const AllCompaniesTemplate = ({ pageContext, data }) => {

  const { currentPage, numPages } = pageContext

  const allCompanies = useCompaniesQuery().allWpCompany.nodes

  const pagination = {
    currentPage,
    numPages,
    isFirst: currentPage === 1,
    isLast: currentPage === numPages,
    prevPage: currentPage - 1 === 1 ? '/companies/' : `/companies/${ currentPage - 1 }`,
    nextPage: `/companies/${currentPage + 1}`,
  }

  const { title, seo } = data.wpPage

  return (
    <Layout seo={ seo }>
      <CompaniesHero
        heroTitle={ title }
        breadcrumbs={ seo.breadcrumbs }
      />
      {/* <CompaniesExchanges /> */}
      <CompaniesContent>
        <CompaniesTop pagination={ pagination } total={ allCompanies.length } />
        <CompaniesBody>
          <SideBar>
            <SideBarContent>
              <CompaniesSector title="По секторам" />
            </SideBarContent>
          </SideBar>
          <CompaniesMain>
            <CompaniesList companies={ data.allWpCompany.nodes } list/>
            <CompaniesBottom pagination={ pagination }/>
          </CompaniesMain>
        </CompaniesBody>
      </CompaniesContent>
    </Layout>
  )
}

export default AllCompaniesTemplate

export const listAllCompanies = graphql `
  query Companies($skip: Int!, $limit: Int!) {
    allWpCompany(
      sort: {fields: ancestors___nodes___date, order: DESC}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        title
        uri
        excerpt
        companyFields {
          companyAddress
          companyPhone
          companySite
          companyTiker
        }
        sectors {
          nodes {
            name
          }
        }
        countries {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100, height: 100, placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
      }
    }
    wpPage(title: { eq: "Обзор компаний" }) {
      id
      title
      status
      content
      seo {
        breadcrumbs {
          text
          url
        }
        canonical
        metaDesc
        opengraphDescription
        opengraphModifiedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        schema {
          articleType
          pageType
          raw
        }
        title
        twitterDescription
        twitterTitle
      }
    }
  }
`