import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BiArrowBack } from "react-icons/bi";

const PaginationNav = ({ pagination }) => {
  return (
    <PaginationNavBlock>
      { !pagination.isFirst ? <Link className="Btn Btn-zero" to={ pagination.prevPage }>
        <BiArrowBack className="Pagination__arrow-left" />
        <span>Назад</span>
      </Link> : null}
      { !pagination.isLast ? <Link className="Btn" to={ pagination.nextPage }>
        <span>Ещё компании</span>
        <BiArrowBack className="Pagination__arrow-right" />
      </Link> : null}
    </PaginationNavBlock>
  )
}

export default PaginationNav

const PaginationNavBlock = styled.div `

  display: flex;
  justify-content: center;

  .Btn + .Btn {
    margin-left: 1rem;
  }

  .Pagination__arrow-left {
    margin-right: 1rem;
  }

  .Pagination__arrow-right {
    margin-left: 1rem;
    transform: rotate(180deg);
  }

  .isDidabled {
    color: gray;
    pointer-events: none;
    opacity: 0.5;
    text-decoration: none;
  }
`
